

























import { defineComponent, computed } from '@vue/composition-api'
export default defineComponent({
  name: 'name',
  props: {
    hasCredits: { type: Boolean, require: true },
    isAllowed: { type: Boolean, required: true },
    isAfterSeasonEndDate: { type: Boolean, required: true },
    label: { type: String, required: true },
  },
  components: {},
  setup(props) {
    const text = computed(() => props.label.toLowerCase())
    return { text }
  },
})
