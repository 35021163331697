







































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'

export default defineComponent({
  name: 'DayOfWeekMultiPicker',
  components: {
    SelectInput,
    RadioGroupInput,
  },
  props: {
    /**
     * show days is a list of Days that can be shown, all if not set.
     */
    showDays: { type: Array as PropType<string[]>, required: false },
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    horizontal: { type: Boolean, default: false, required: false },
    value: { type: (null as unknown) as PropType<number | null>, required: true },
    mode: { type: String, required: false, default: 'select' },
    allOption: { type: Boolean, required: false, default: false },
    warpperClass: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const internalValue = ref<number | null>(props.value)
    const options = ref(days)

    const list = computed(() => {
      if (props.showDays?.length) {
        return options.value.filter((x) => props.showDays!.indexOf(x.name) >= 0)
      }

      if (props.allOption && !options.value.find((o) => o.name == 'All')) {
        options.value.unshift({
          name: 'All',
          abbr3: 'All',
          abbr2: 'all',
          selected: false,
          enabled: true,
          ordinal: null,
          value: null,
        })
      }
      return options.value
    })

    function handleChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    return {
      internalValue,
      list,
      handleChange,
    }
  },
})
