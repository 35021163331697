import store from '@/store'
export interface RecipientGroupOptions {
  optionText: string
  optionValue: string
}

//since these are directly tied to the database(ouch), I am putting them in one place here
//plus Cindy had a note previously to get rid of the file these were in.
export enum RecipientTypes {
  PARENTS = 'Parents/Guardians',
  COACHES = 'Coaches',
  REFEREES = 'Referees',
  COMMISSIONERS = 'Commissioners',
  OTHERS = 'Other Volunteers',
}

//add ability to change the option descriptions without killing the database procedure tightly coupled with these values
//this should all probably be refactored to NOT tightly couple the database parameters to front-end descriptions.
export function getRecipientGroups() {
  const retval = [] as RecipientGroupOptions[]
  retval.push({ optionText: RecipientTypes.PARENTS, optionValue: RecipientTypes.PARENTS })
  retval.push({ optionText: RecipientTypes.COACHES, optionValue: RecipientTypes.COACHES })
  if (!store.getters.leagueAbstraction.isUpwardSelect) {
    retval.push({
      optionText: store.getters.leagueAbstraction.refLabel + 's',
      optionValue: RecipientTypes.REFEREES,
    })
  }
  retval.push({ optionText: RecipientTypes.COMMISSIONERS, optionValue: RecipientTypes.COMMISSIONERS })
  retval.push({ optionText: RecipientTypes.OTHERS, optionValue: RecipientTypes.OTHERS })
  return retval
}
