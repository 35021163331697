











import { Component, Vue, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import RadioGroupInput from '@/elements/RadioGroupInput.vue'

import { programOptions } from '@/views/Programs/Communications/data/filterOptions'
import { isCheer } from '@/lib/common/ProgramTypes'
import store from '@/store'

//These are strings because that is what the API expects
@Component({
  components: { RadioGroupInput },
  data() {
    return { programOptions }
  },
})
export default class MessageProgramSelector extends Vue {
  @Prop({ type: String, required: false })
  private readonly value!: string

  currentOption = ''

  clear() {
    this.$emit('input', '')
  }

  emitValue(val: string) {
    if (val === programOptions[0].value) {
      this.clear()
    }
    this.currentOption = val
    this.$emit('input', val)
  }

  get program() {
    return this.optionaValue()
  }
  set program(v: string) {
    const id = this.programId(v)
    this.$emit('input', id)
  }

  get programs() {
    return cloneDeep(store.getters.leagueAbstraction.currentItem.programs) ?? []
  }

  /**
   * Converts an option (cheer or sport) to a typeProgramID
   */
  programId(option: string) {
    if (option == 'ALL') return ''

    if (option == 'CHEER') {
      const cheer = this.programs.find((p) => isCheer(p.typeProgramID))
      return cheer?.typeProgramID ?? ''
    }
    //this.program will be SPORT
    const sport = this.programs.find((p) => !isCheer(p.typeProgramID))
    return sport?.typeProgramID ?? ''
  }

  /**
   * Converts a typeProgramID to an option (cheer or sport)
   */
  optionaValue() {
    if (this.value == '') return programOptions[2].value // ALL

    const program = this.programs.find((p) => p.typeProgramID == this.value)

    if (program && isCheer(program.typeProgramID)) {
      return programOptions[1].value // CHEER
    }
    return programOptions[0].value // SPORT
  }
}
