









import { defineComponent, PropType, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import CheckboxGroupInput from '@/elements/CheckboxGroupInput.vue'

import { getRecipientGroups } from '@/views/Programs/Communications/ext/RecipientGroups'

export default defineComponent({
  name: 'RecipientGroupInput',
  props: {
    value: { type: Array as PropType<string[]>, required: true },
    label: { type: String, required: false, default: 'Recipient Groups' },
  },
  components: { CheckboxGroupInput },
  setup(props, { emit }) {
    const selectedGroups = computed({
      get() {
        return props.value
      },
      set(val: string[]) {
        const x = cloneDeep(val)
        emit('input', x)
      },
    })

    const recipientGroups = getRecipientGroups()

    return { selectedGroups, recipientGroups }
  },
})
