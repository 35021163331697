






















import { Component, Vue, Prop } from 'vue-property-decorator'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import {
  GospelVideoOptions,
  GospelVideoOption,
} from '@/components/Communication/GospelViedoSelector/GospelVideoSelectorData'

//These are strings because that is what the API expects
@Component({
  components: { RadioGroupInput },
})
export default class GospelVideoSelector extends Vue {
  @Prop({ type: String, required: false })
  private readonly value!: string

  currentOption = ''
  theGospelTruthFull = GospelVideoOptions.get('TheGospelTruthFull')
  theGospelTruthShort = GospelVideoOptions.get('TheGospelTruthShort')
  fromDoToDone = GospelVideoOptions.get('FromDoToDone')
  thePortal = GospelVideoOptions.get('ThePortal')
  situationRoom = GospelVideoOptions.get('SituationRoom')
  gospelLiterally = GospelVideoOptions.get('GospelLiterally')
  graffitiGospel = GospelVideoOptions.get('GraffitiGospel')
  greatestSuperheroGospel = GospelVideoOptions.get('GreatestSuperheroGospel')
  none = GospelVideoOptions.get('None')

  list = [
    { value: this.theGospelTruthFull?.id, text: this.theGospelTruthFull?.title },
    { value: this.theGospelTruthShort?.id, text: this.theGospelTruthShort?.title },
    { value: this.fromDoToDone?.id, text: this.fromDoToDone?.title },
    { value: this.thePortal?.id, text: this.thePortal?.title },
    { value: this.situationRoom?.id, text: this.situationRoom?.title },
    { value: this.gospelLiterally?.id, text: this.gospelLiterally?.title },
    { value: this.graffitiGospel?.id, text: this.graffitiGospel?.title },
    { value: this.greatestSuperheroGospel?.id, text: this.greatestSuperheroGospel?.title },
    { value: this.none?.id, text: this.none?.title },
  ]

  get url(): string {
    if (!this.currentGospelOption.url) return ''
    return this.currentGospelOption.url
  }

  get description(): string {
    if (!this.currentGospelOption.description) return ''
    return this.currentGospelOption.description
  }

  get currentGospelOption(): GospelVideoOption {
    const option = GospelVideoOptions.get(this.currentOption)
    return option ? option : ({} as GospelVideoOption)
  }

  clear() {
    this.$emit('input', '')
  }

  emitValue(val: string) {
    if (val === this.none?.id) {
      this.clear()
    }
    this.currentOption = val
    this.$emit('input', val)
  }
}
