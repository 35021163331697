export interface GospelVideoOption {
  id: string
  title: string
  url: string
  description: string
  emailText: string
  smsText: string
}

const theGospelTruthShort: GospelVideoOption = {
  id: 'TheGospelTruthShort',
  title: 'The Gospel Truth (Short Version)',
  url: 'https://www.upward.org/the-gospel-truth-shortversion/',
  description: `Is the story of Jesus true? A former police detective walks us through some of the evidence that shows that Jesus stands apart from the myths of the past. The biblical story of Jesus is reliable, and it invites each of us to take a step of faith toward Him.`,
  emailText:
    'Parents and players - Here is an important video to watch as part of our season. Your coach will take the time to discuss what you viewed in the video at an upcoming practice',
  smsText: 'Upward Sports: Here is an important video to watch as part of our season. ',
}

const theGospelTruthFull: GospelVideoOption = {
  id: 'TheGospelTruthFull',
  title: 'The Gospel Truth (Full Version)',
  url: 'https://www.upward.org/the-gospel-truth-fullversion/',
  description: `Is the story of Jesus true? A former police detective walks us through some of the evidence that shows that Jesus stands apart from the myths of the past. The biblical story of Jesus is reliable, and it invites each of us to take a step of faith toward Him.`,
  emailText:
    'Parents and players - Here is an important video to watch as part of our season. Your coach will take the time to discuss what you viewed in the video at an upcoming practice',
  smsText: 'Upward Sports: Here is an important video to watch as part of our season. ',
}

const fromDoToDone: GospelVideoOption = {
  id: 'FromDoToDone',
  title: 'From Do to Done',
  url: 'https://www.upward.org/from-do-to-done/',
  description: `Go from what we think of as a list of things we have to do to become a Christian, to understanding what God has already done for us.`,
  emailText:
    'Parents and players - Here is an important video to watch as part of our season. Your coach will take the time to discuss what you viewed in the video at an upcoming practice',
  smsText: 'Upward Sports: Here is an important video to watch as part of our season. ',
}

const thePortal: GospelVideoOption = {
  id: 'ThePortal',
  title: 'The Portal',
  url: 'https://www.upward.org/theportal/',
  description: `Look through a portal to see how God can transform feelings of despair, loneliness, and obscurity to hope, companionship, and being seen by God the Creator.`,
  emailText:
    'Parents and players - Here is an important video to watch as part of our season. Your coach will take the time to discuss what you viewed in the video at an upcoming practice',
  smsText: 'Upward Sports: Here is an important video to watch as part of our season. ',
}

const situationRoom: GospelVideoOption = {
  id: 'SituationRoom',
  title: 'Situation Room',
  url: 'https://www.upward.org/situationroom/',
  description: `Humanity has a problem. A big problem. And there's only one solution.`,
  emailText:
    'Parents and players - Here is an important video to watch as part of our season. Your coach will take the time to discuss what you viewed in the video at an upcoming practice',
  smsText: 'Upward Sports: Here is an important video to watch as part of our season. ',
}

const gospelLiterally: GospelVideoOption = {
  id: 'GospelLiterally',
  title: 'The Gospel Literally',
  url: 'https://www.upward.org/thegospelliterally/',
  description:
    'Are we too short to be with God? Does God pay us with death? Why does God want our hearts? All these confusing questions get answered.',
  emailText:
    'Parents and players - Here is an important video to watch as part of our season. Your coach will take the time to discuss what you viewed in the video at an upcoming practice',
  smsText: 'Upward Sports: Here is an important video to watch as part of our season. ',
}

const graffitiGospel: GospelVideoOption = {
  id: 'GraffitiGospel',
  title: 'Graffiti Gospel',
  url: 'https://www.upward.org/graffitigospel',
  description: 'Watch an artist share the Gospel while creating a graffiti art mural.',
  emailText:
    'Parents and players - Here is an important video to watch as part of our season. Your coach will take the time to discuss what you viewed in the video at an upcoming practice',
  smsText: 'Upward Sports: Here is an important video to watch as part of our season. ',
}

const greatestSuperheroGospel: GospelVideoOption = {
  id: 'GreatestSuperheroGospel',
  title: 'The Greatest Superhero',
  url: 'https://www.upward.org/greatestsuperhero',
  description: 'Learn with Captain Kaboom about the greatest superhero.',
  emailText:
    'Parents and players - Here is an important video to watch as part of our season. Your coach will take the time to discuss what you viewed in the video at an upcoming practice',
  smsText: 'Upward Sports: Here is an important video to watch as part of our season. ',
}

const none: GospelVideoOption = {
  id: 'None',
  title: 'None',
  url: '',
  description: '',
  emailText: '',
  smsText: '',
}

export const GospelVideoOptions = new Map([
  ['FromDoToDone', fromDoToDone],
  ['ThePortal', thePortal],
  ['SituationRoom', situationRoom],
  ['GospelLiterally', gospelLiterally],
  ['GraffitiGospel', graffitiGospel],
  ['GreatestSuperheroGospel', greatestSuperheroGospel],
  ['TheGospelTruthFull', theGospelTruthFull],
  ['TheGospelTruthShort', theGospelTruthShort],
  ['None', none],
])
