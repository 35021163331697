







import { defineComponent, ref, onMounted } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
export default defineComponent({
  name: 'ToClipboardBtn',
  props: {
    stringArray: { type: Array, required: false, default: '' },
  },
  components: { Loading },
  setup(props) {
    const copyAddresses = ref(false)
    const showSpinner = ref(false)

    onMounted(() => {
      document.addEventListener('copy', copyEmailAddress)
    })

    function copyAddress() {
      showSpinner.value = true

      copyAddresses.value = true
      document.execCommand('copy')
      copyAddresses.value = false

      setTimeout(() => {
        showSpinner.value = false
      }, 500)
    }

    const copyGuard = ref(false)
    function copyEmailAddress(e: any) {
      if (!copyAddresses.value || copyGuard.value) {
        return
      }
      copyGuard.value = true
      const csv = props.stringArray.reduce((s, a) => `${s},${a}`)

      e.clipboardData.setData('text/plain', csv)

      // This is necessary to prevent the current document selection from
      // being written to the clipboard.
      e.preventDefault()
      copyGuard.value = false
    }
    return { copyAddress, showSpinner, copyAddresses }
  },
})
