













import { defineComponent, watch, onMounted } from '@vue/composition-api'
import { getProps, useTextlike } from '@/composables/TextLike'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  name: 'InputlessStringValidator',
  components: {
    ValidationProvider,
  },
  props: {
    value: { type: String, default: '', required: false },
    ...getProps(),
  },
  setup(props, ctx) {
    const { iconClick, internalValue, handleInput, handleChange, handleBlur } = useTextlike<string>(
      props,
      ctx
    )

    internalValue.value = props.value

    function setInternalValue() {
      internalValue.value = props.value
    }

    watch(
      () => props.value,
      () => {
        setInternalValue()
      }
    )

    onMounted(() => {
      setInternalValue()
    })

    return {
      iconClick,
      internalValue,
      handleInput,
      handleChange,
      handleBlur,
    }
  },
})
