import { Attachment } from '@/GeneratedTypes/Communication/Attachment'
import { CommunicationCriteria } from '@/GeneratedTypes/Communication/CommunicationCriteria'
import { EmailMessage } from '@/GeneratedTypes/Communication/EmailMessage'

export class EmailMessageImpl implements EmailMessage {
  accountNumber!: string | null
  upwardLeagueID!: string | null
  displayName!: string | null
  replyTo!: string | null
  emailSubject!: string | null
  messageBody!: string | null
  to!: string[] | null
  attachments!: Attachment[] | null
  sendCommunicationOn?: Date | null | undefined
  minutesToAdd?: number | null | undefined
  recipientCriteria?: CommunicationCriteria | null | undefined
}

export function getEmptyEmail(): EmailMessage {
  return {
    accountNumber: '',
    upwardLeagueID: '',
    displayName: '',
    replyTo: '',
    emailSubject: '',
    messageBody: '',
    to: [],
    attachments: [],
  }
}
