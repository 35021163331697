



































import { defineComponent, ref, watch, computed, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import uuid from 'uuid'

import Tree from '@/components/Tree.vue'
import Loading from '@/elements/Loading.vue'

import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'

import store from '@/store'

interface TreeNode {
  'item-key': string
  id?: number
  name: string
  children: TreeNode[]
}

export default defineComponent({
  name: 'DivisionTeamSelector',
  props: {
    value: { type: Array as PropType<number[]>, required: false, default: () => [] }, //array of selected teamID's
  },
  components: { Tree, Loading },
  setup(props, { emit }) {
    let fetchedTeams = ref<DivisionTeamInfo[] | null>(null)
    let divisionTree = ref<TreeNode[]>([])
    const rootid = uuid.v4()
    const openNodes = ref([rootid])

    const upwId = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID ?? '')

    const sortedTeams = computed(() => {
      const s = cloneDeep(selected.value) as TreeNode[]
      return s.sort((a, b) => (a.name > b.name ? 1 : -1))
    })

    const selected = computed({
      get(): TreeNode[] {
        if (fetchedTeams.value) {
          return fetchedTeams.value
            .filter((t) => props.value.includes(t.teamID))
            .map((tt) => getTreeNodeTeam(tt))
        } else {
          return []
        }
      },
      set(v: TreeNode[]) {
        const ids = v.map((t) => t.id ?? 0)
        emit('input', ids)
      },
    })

    function getTreeNodeTeam(t: DivisionTeamInfo): TreeNode {
      return {
        'item-key': `${t.divisionID}${t.teamID}`,
        id: t.teamID,
        name: t.teamName ?? '',
        children: [],
      }
    }

    function makeTree() {
      //root node
      const tree: TreeNode = {
        'item-key': rootid,
        name: 'All Divisions',
        children: [],
      }

      //all other nodes
      if (fetchedTeams.value?.length) {
        const divisions = fetchedTeams.value.reduce((arr: TreeNode[], d) => {
          getDivision(arr, d)
          return arr
        }, [])
        tree.children = divisions
      }
      return tree
    }

    function getDivision(arr: any, d: DivisionTeamInfo) {
      if (!divisionExists(arr, d.divisionID)) {
        arr.push({
          'item-key': d.divisionID,
          id: d.divisionID,
          name: d.divisionName ?? '',
          children: getTeams(d.divisionID),
        })
      }
    }

    function getTeams(divisionId: number) {
      const x = fetchedTeams.value?.reduce((arr: TreeNode[], t) => {
        if (t.divisionID == divisionId) {
          arr.push({
            'item-key': `${t.divisionID}${t.teamID}`,
            id: t.teamID,
            name: t.teamName ?? '',
          } as TreeNode)
        }
        return arr
      }, [])
      return x ?? []
    }

    function divisionExists(divisions: TreeNode[] | undefined, divisionId: number) {
      if (divisions == undefined) return false
      if (divisions.find((d) => d.id == divisionId)) return true
      return false
    }

    const loading = ref(false)
    async function fetchTeams() {
      loading.value = true
      try {
        fetchedTeams.value = await store.dispatch.teams.fetchTeams({ upwId: upwId.value })
      } finally {
        loading.value = false
      }
    }

    watch(
      () => upwId.value,
      async () => {
        await fetchTeams()
      },
      { immediate: true }
    )
    watch(
      () => fetchedTeams.value,
      () => {
        divisionTree.value?.push(makeTree())
      }
    )
    return { selected, divisionTree, loading, sortedTeams, openNodes }
  },
})
