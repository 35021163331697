interface Option {
  label: string
  value: string
}
export const paymentOptions: Option[] = [
  { label: 'Player has paid', value: 'Paid' },
  { label: 'Player has NOT paid', value: 'Not Paid' },
  { label: 'Paid and unpaid', value: 'All' },
]

export const evalOptions: Option[] = [
  { label: 'Player has been evaluated', value: 'Evaluated' },
  { label: 'Player has NOT been evaluated', value: 'Not Evaluated' },
  { label: 'Evaluated and unevaluated', value: 'All' },
]

export const coachAssignOptions: Option[] = [
  { label: 'Assigned to a team', value: 'Assigned to a Team' },
  { label: 'Unassigned', value: 'Unassigned' },
  { label: 'Unapproved', value: 'Unapproved' },
  { label: 'All Approved', value: 'All Approved' },
]

export const programOptions: Option[] = [
  { value: 'PLAYER', label: 'Sport Only' },
  { value: 'CHEER', label: 'Cheer Only' },
  { value: 'ALL', label: 'All' },
]

export const FilterByOptions: Option[] = [
  { label: 'All', value: 'all' },
  { label: 'Division', value: 'division' },
  { label: 'Team', value: 'team' },
  { label: 'Practice Night', value: 'night' },
]

export const multiPartnerOptions = [
  { label: 'Communicate with My Church Contacts Only', value: false },
  { label: 'Communicate with All Contacts', value: true },
]

export const genderOptions: Option[] = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Both', value: 'E' },
]
