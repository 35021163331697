



















































































import { defineComponent, PropType, computed, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import Modal from '@/components/Modal.vue'
import GradeRangeDropdowns from '@/elements/GradeRangeDropdowns.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import MessageProgramSelector from '@/components/Communication/MessageProgramSelector.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import DayOfWeekPicker from '@/elements/DayOfWeekPicker.vue'
import DivisionTeamSelector from '@/views/Programs/Communications/vue/Filters/DivisionTeamSelector.vue'
import PracticeFacilitySelector from '@/elements/PracticeFacilitySelector.vue'

import {
  paymentOptions,
  evalOptions,
  coachAssignOptions,
  multiPartnerOptions,
  genderOptions,
} from '@/views/Programs/Communications/data/filterOptions'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import { MessageFilters } from '@/views/Programs/Communications/model/MessageFilters'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

import store from '@/store'

export default defineComponent({
  name: 'MessageFilterModal',
  props: {
    value: { type: Boolean, required: true },
    filters: { type: Object as PropType<MessageFilters>, required: true },
  },
  data() {
    return { paymentOptions, evalOptions, coachAssignOptions, multiPartnerOptions, genderOptions }
  },
  components: {
    Modal,
    GradeRangeDropdowns,
    RadioGroupInput,
    MessageProgramSelector,
    DayOfWeekPicker,
    SwitchSlot,
    DivisionTeamSelector,
    PracticeFacilitySelector,
  },
  setup(props, { emit }) {
    const localFilters = ref(props.filters)
    const composeFilters = (): MessageFilters => {
      return {
        teams: teams.value,
        practiceNight: days.find((d) => d.value == practice.value)?.name ?? 'All',
        startGrade: startGrade.value,
        endGrade: endGrade.value,
        paymentStatus: pymtStatus.value,
        evalStatus: evalStatus.value,
        coachTeamStatus: coachTeamStatus.value,
        typeProgramID: typeProgramID.value,
        viewAllLeagueContacts: viewAllLeagueContacts.value,
        practiceFacilityIDs: practiceFacilities.value,
        gender: gender.value,
      }
    }
    const pymtStatus = computed({
      get(): string {
        return props.filters.paymentStatus ?? 'All'
      },
      set(v: string) {
        const filters = composeFilters()
        filters.paymentStatus = v
        emitFilter(filters)
      },
    })

    const isLeague = computed(() => {
      return store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    })

    const isByAge = computed(() => store.getters.leagueAbstraction.isByAge)

    const evalStatus = computed({
      get(): string {
        return props.filters.evalStatus ?? 'All'
      },
      set(v: string) {
        const filters = composeFilters()
        filters.evalStatus = v
        emitFilter(filters)
      },
    })

    const typeProgramID = computed({
      get(): string {
        return props.filters.typeProgramID ?? ''
      },
      set(v: string) {
        const filters = composeFilters()
        filters.typeProgramID = v
        emitFilter(filters)
      },
    })

    const viewAllLeagueContacts = computed({
      get(): boolean {
        return props.filters.viewAllLeagueContacts ?? false
      },
      set(v: boolean) {
        const filters = composeFilters()
        filters.viewAllLeagueContacts = v
        emitFilter(filters)
      },
    })

    const coachTeamStatus = computed({
      get(): string {
        return props.filters.coachTeamStatus ?? 'All'
      },
      set(v: string) {
        const filters = composeFilters()
        filters.coachTeamStatus = v
        emitFilter(filters)
      },
    })

    const practice = computed({
      get(): number | null {
        return days.find((p) => p.name == props.filters.practiceNight)?.value ?? null
      },
      set(v: number | null) {
        const filters = composeFilters()
        const night = days.find((d) => d.value == v)?.name ?? null
        filters.practiceNight = night
        emitFilter(filters)
      },
    })

    const teams = computed({
      get(): number[] {
        return props.filters.teams ?? []
      },
      set(v: number[]) {
        const filters = composeFilters()
        filters.teams = cloneDeep(v)
        emitFilter(filters)
      },
    })

    const practiceFacilities = computed({
      get(): number[] {
        return props.filters.practiceFacilityIDs ?? []
      },
      set(v: number[]) {
        const filters = composeFilters()
        filters.practiceFacilityIDs = cloneDeep(v)
        emitFilter(filters)
      },
    })

    const show = computed({
      get(): boolean {
        return props.value
      },
      set(v: boolean) {
        emit('input', v)
      },
    })

    function close() {
      emit('input', false)
    }

    function setGrades(grades: string[]) {
      const filters = composeFilters()
      filters.startGrade = grades[0]
      filters.endGrade = grades[grades.length - 1]
      emitFilter(filters)
    }

    const startGrade = computed({
      get(): string {
        return props.filters.startGrade ?? 'K3'
      },
      set(v: string) {
        const filters = composeFilters()
        filters.startGrade = v
        emitFilter(filters)
      },
    })

    const endGrade = computed({
      get(): string {
        return props.filters.endGrade ?? '12th'
      },
      set(v: string) {
        const filters = composeFilters()
        filters.endGrade = v
        emitFilter(filters)
      },
    })

    const gender = computed({
      get(): string {
        return props.filters.gender ?? 'E'
      },
      set(v: string) {
        const filters = composeFilters()
        filters.gender = v
        emitFilter(filters)
      },
    })

    const emitFilter = (filters: MessageFilters) => {
      emit('filterChange', filters)
    }

    const programs = computed(() => {
      return cloneDeep(store.getters.leagueAbstraction.currentItem.programs) ?? []
    })

    const hasPartners = computed(() => {
      return (store.getters.leagueAbstraction.currentItem.accounts ?? []).length > 1
    })

    const hasCheer = computed(() => {
      return programs.value.length > 1
    })

    const small = computed(() => {
      return !hasCheer.value && !hasPartners.value
    })

    const slots = computed(() => {
      return [
        { text: 'Practice Nights', value: 'nights' },
        { text: 'Division/Teams', value: 'teams' },
        { text: 'Practice Facilities', value: 'practiceFacilities' },
      ].filter((i) => {
        if (!isLeague.value) return i.value === 'teams'
        return true
      })
    })
    const slot = ref('nights')
    function slotChange(v: string) {
      const filters = composeFilters()
      if (v === 'teams') {
        filters.practiceNight = null
        filters.practiceFacilityIDs = []
      } else if (v === 'nights') {
        filters.practiceFacilityIDs = []
        filters.teams = []
        teams.value = []
      } else if (v === 'practiceFacilities') {
        filters.teams = []
        teams.value = []
        filters.practiceNight = null
      }
      emitFilter(filters)
    }
    return {
      localFilters,
      show,
      setGrades,
      startGrade,
      endGrade,
      hasCheer,
      hasPartners,
      small,
      pymtStatus,
      evalStatus,
      coachTeamStatus,
      typeProgramID,
      viewAllLeagueContacts,
      slot,
      slots,
      practice,
      teams,
      close,
      isLeague,
      practiceFacilities,
      slotChange,
      gender,
      isByAge,
    }
  },
  get isLeague() {
    const opportunityType = store.getters.leagueAbstraction.programType
    if (opportunityType == UpwardOpportunityTypes.LEAGUE) {
      return true
    }
    return false
  },
})
