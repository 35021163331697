//logic shared by SMS and Email messages
import { ref, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { MessageFilters, getEmptyMessageFilters } from '@/views/Programs/Communications/model/MessageFilters'
import { EmailMessageImpl } from '@/views/Programs/Communications/ext/EmailMessageImpl'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import communicationsClient from '@/clients/communicationsClient'
import { mergeAllRecipients } from '@/views/Programs/Communications/ext/allRecipients'
import { ProgramTypes } from '@/lib/common/ProgramTypes'
import store from '@/store'
import { EmailSMSLeagueMessageInfo } from '@/GeneratedTypes/Communication/EmailSMSLeagueMessage'
import dayjs from 'dayjs'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { getProperGradeLabel } from '@/services/gradeService'

export function messageLogic() {
  const allTeams = ref<DivisionTeamInfo[]>([])
  const errorOccurred = ref(false)
  const showConfirmationModal = ref(false)
  const showFilters = ref(false)
  const loadingRecipients = ref(false)
  const showSendDrop = ref<boolean>(false)
  const showScheduleSendModal = ref<boolean>(false)
  const sendCommunicationOn = ref<Date | null>(null)
  const minutesToAdd = computed((): number | null => {
    if (!sendCommunicationOn.value) return null
    const now = dayjs(new Date(Date.now()))
    const sendOn = dayjs(sendCommunicationOn.value)
    return sendOn.diff(now, 'minute', false)
  })
  const toggleSendDrop = () => {
    showSendDrop.value = !showSendDrop.value
  }
  const sendDropAway = () => {
    showSendDrop.value = false
  }
  const sendCommunicationOnFormatted = computed((): string => {
    return (
      sendCommunicationOn.value?.toLocaleTimeString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      }) || ''
    )
  })
  const filters = ref<MessageFilters>(getEmptyMessageFilters())

  const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
  const providerInfo = computed(() => store.getters.communications.currentItem)
  const upwardLeagueID = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID)
  const hasPartners = computed(() => {
    return (store.getters.leagueAbstraction.currentItem.accounts ?? []).length > 1
  })

  function filterClick() {
    showFilters.value = true
  }

  const isSending = ref(false)
  async function send(messageData: EmailMessageImpl, type: 'sendemail' | 'sendsms') {
    isSending.value = true
    errorOccurred.value = false
    try {
      await communicationsClient.sendMessage(messageData, type)
    } catch (e) {
      errorOccurred.value = true
    } finally {
      isSending.value = false
      showConfirmationModal.value = true
    }
  }
  async function retrieveMessage(messageID: number, type: 'email' | 'sms') {
    return type === 'email' ? await getEmailMessage(messageID) : await getSmsMessage(messageID)
  }
  async function getEmailMessage(messageID: number): Promise<EmailSMSLeagueMessageInfo | null> {
    return await communicationsClient.retrieveMessage(messageID, communicationsClient.MessageTypes.Email)
  }
  async function getSmsMessage(messageID: number): Promise<EmailSMSLeagueMessageInfo | null> {
    return await communicationsClient.retrieveMessage(messageID, communicationsClient.MessageTypes.Sms)
  }

  const fetchRecipients = async (preview: PreviewMessage) => {
    try {
      loadingRecipients.value = true
      await store.dispatch.communications.retrieveMessagePreview({
        previewMessage: preview,
      })
    } finally {
      loadingRecipients.value = false
    }
  }

  const previewMessage = computed(() => store.getters.communications.messagePreview)

  const allRecipients = computed(() => {
    return mergeAllRecipients(cloneDeep(previewMessage.value))
  })

  function filterBuilder(list: string, label: string, value: string | null) {
    return `${list} <span class="text-muted small">${label}:</span> <b class="small font-italic mr-2">${value}</b>`
  }

  const filterList = computed(() => {
    let list = ''
    const f = filters.value
    if (f.paymentStatus != 'All') {
      list = filterBuilder(list, 'payment status', f.paymentStatus)
    }

    if (f.evalStatus != 'All') {
      list = filterBuilder(list, 'eval status', f.evalStatus)
    }

    if (f.coachTeamStatus != 'All Approved') {
      list = filterBuilder(list, 'coach assignment', f.coachTeamStatus)
    }

    if (f.typeProgramID != '') {
      list = filterBuilder(list, 'program', ProgramTypes[f.typeProgramID ?? ''])
    }

    if (hasPartners.value) {
      if (f.viewAllLeagueContacts) {
        list = filterBuilder(list, 'recipients across <u>all</u> churches', 'yes')
      } else {
        list = filterBuilder(list, 'recipients from <u>my church only</u>', 'yes')
      }
    }

    if (f.startGrade != 'K3' || f.endGrade != '12th') {
      const isByAge = store.getters.leagueAbstraction.isByAge
      list = filterBuilder(
        list,
        isByAge ? 'ages' : 'grades',
        `${getProperGradeLabel(f.startGrade!, isByAge, '')} to ${getProperGradeLabel(
          f.endGrade!,
          isByAge,
          ''
        )}`
      )
    }
    if (f.gender != 'E') {
      list = filterBuilder(list, 'participant gender', f.gender == 'M' ? 'Male' : 'Female')
    }
    if (f.practiceNight && f.practiceNight != 'All') {
      list = filterBuilder(list, 'practice night', f.practiceNight)
    }
    if (f.teams.length > 0) {
      const names = f.teams
        .map((t) => allTeams.value.find((at) => at.teamID == t)?.teamName)
        .filter((n) => {
          return !!n
        })
      list = filterBuilder(list, 'teams', names.join(', '))
    }
    return list
  })

  const seasonEndDate = computed(() => {
    switch (store.getters.leagueAbstraction.programType) {
      case UpwardOpportunityTypes.LEAGUE:
        return store.getters.leagues.currentItem.seasonEndDate
      case UpwardOpportunityTypes.CAMP:
        return store.getters.camps.currentItem.campEndDate
      default:
        null
    }
  })

  return {
    showConfirmationModal,
    showFilters,
    filterClick,
    filters,
    loadingRecipients,
    send,
    isSending,
    fetchRecipients,
    allRecipients,
    previewMessage,
    filterList,
    accountNumber,
    upwardLeagueID,
    providerInfo,
    retrieveMessage,
    showSendDrop,
    showScheduleSendModal,
    sendDropAway,
    toggleSendDrop,
    minutesToAdd,
    sendCommunicationOn,
    sendCommunicationOnFormatted,
    allTeams,
    errorOccurred,
    seasonEndDate,
  }
}
