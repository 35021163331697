export interface Recipients {
  selectedGroups: string[]
  toDelimited: string[]
}

export function getEmptyRecipients(): Recipients {
  return {
    selectedGroups: [],
    toDelimited: [],
  }
}
