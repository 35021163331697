import { CommunicationCriteria } from '@/GeneratedTypes/Communication/CommunicationCriteria'
import {
  paymentOptions,
  evalOptions,
  coachAssignOptions,
} from '@/views/Programs/Communications/data/filterOptions'

export interface MessageFilters {
  teams: number[]
  practiceNight: string | null
  startGrade: string | null
  endGrade: string | null
  paymentStatus: string | null
  evalStatus: string | null
  coachTeamStatus: string | null
  typeProgramID: string | null
  viewAllLeagueContacts: boolean
  practiceFacilityIDs: number[]
  gender: string | null
}

export function getEmptyMessageFilters(): MessageFilters {
  return {
    teams: [],
    practiceNight: null,
    startGrade: 'K3',
    endGrade: '12th',
    paymentStatus: paymentOptions[2].value, // All
    evalStatus: evalOptions[2].value, //All
    coachTeamStatus: coachAssignOptions[3].value, // All Approved
    typeProgramID: '',
    viewAllLeagueContacts: false,
    practiceFacilityIDs: [],
    gender: 'E',
  }
}
export function convertToMessageFilters(criteria?: CommunicationCriteria | null): MessageFilters {
  const empty = getEmptyMessageFilters()
  if (!criteria) return empty
  return {
    teams: criteria.teams ?? empty.teams,
    practiceNight: criteria.practiceNight ?? empty.practiceNight,
    startGrade: criteria.startGrade ?? empty.startGrade,
    endGrade: criteria.endGrade ?? empty.endGrade,
    paymentStatus: criteria.paymentStatus ?? empty.paymentStatus,
    evalStatus: criteria.evalStatus ?? empty.evalStatus,
    coachTeamStatus: criteria.coachTeamStatus ?? empty.coachTeamStatus,
    typeProgramID: criteria.typeProgramID ?? empty.typeProgramID,
    viewAllLeagueContacts: criteria.viewAllLeagueContacts ?? empty.viewAllLeagueContacts,
    practiceFacilityIDs: criteria.practiceFacilityIDs ?? empty.practiceFacilityIDs,
    gender: criteria.gender ?? empty.gender,
  }
}
