






























import { Component, Vue, Prop } from 'vue-property-decorator'
import InputLabel from '@/elements/InputLabel.vue'
import { isEmailValid } from '@/lib/support/utilities/email/emailUtil'
import { isPhoneValid } from '@/lib/support/utilities/phone/phoneUtil'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import uuid from 'uuid'

@Component({ components: { InputLabel } })
export default class RecipientListPreview extends Vue {
  @Prop({ type: Array, default: () => [], required: false })
  private readonly recipients!: string[]

  @Prop({ type: Array, default: () => [], required: false })
  private readonly recipientGroups!: string[]

  @Prop({ type: String, default: MessageChannelTypes.EMAIL, required: true })
  private readonly channel!: string

  get groups() {
    return this.recipientGroups.map((r) => ({ id: uuid.v4(), group: r }))
  }

  get individuals() {
    return this.recipients.map((r) => ({ id: uuid.v4(), contact: r }))
  }

  private recipientValid(recipient: string) {
    if (this.channel.toLowerCase() === MessageChannelTypes.EMAIL) {
      return isEmailValid(recipient)
    } else {
      return isPhoneValid(recipient)
    }
  }
}
